import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ForParents() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Родителям`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Родителям'
      />

      <section className='flex flex-col items-center '>
        <h1 className='text-3xl text-center'>Родителям</h1>
      </section>
    </Layout>
  );
}

export default ForParents;
